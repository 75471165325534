(function () {
	'use strict';

	const compressor = 0.3643;

	const fnCountUp = (counter, cur, final, inc, type) => {		
		const curText = Math.floor(cur).toLocaleString();
		counter.innerHTML = curText;
		if (cur < final) {
			setTimeout(() => {
				const nextCur = cur + inc < final ? cur + inc : final;
				fnCountUp(counter, nextCur, final, inc, type);
			}, 100);
		} else {
			const textFinal = Math.floor(final).toLocaleString();
			counter.innerHTML = textFinal;
		}
		window.fitText(counter, compressor, 'auto');
	};

	const controller = new ScrollMagic.Controller();
	const compCards = [...document.querySelectorAll('comp-count-cards')];	

	for (let cardIndex = 0, cardLength = compCards.length; cardIndex < cardLength; cardIndex++) {
		const card = compCards[cardIndex];
		const counterTags = [...card.querySelectorAll('header[fit-text]')];
		const counters = [];

		for (let tagIndex = 0, tagLength = counterTags.length; tagIndex < tagLength; tagIndex++) {
			const counter = counterTags[tagIndex];
			const text = parseFloat(counter.innerHTML.replace(/[^\d.-]/g, ''));
			counter.setAttribute('data-final', text);
			counter.innerHTML = '0';
			window.fitText(counter, compressor, 'auto');
			counters.push(counter);
		}

		(new ScrollMagic.Scene({ triggerElement: card, triggerHook: 0.9 })
			.on('enter', () => {
				for (let i = 0, l = counters.length; i < l; i++) {
					const final = counters[i].getAttribute('data-final');
					const type = counters[i].getAttribute('type');
					setTimeout(() => {
						fnCountUp(counters[i], 0, final, final / 10, type);
					}, 1000 * i);
				}
			})
			.addTo(controller));
		
		window.addEventListener('resize', () => {
			for (let i = 0, l = counters.length; i < l; i++) {
				window.fitText(counters[i], 10, 'auto');
			}
		});
	}
})();

