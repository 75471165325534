'use strict';
(function ( $ ) {
	var slideOuts = {},
		duration = 500;

	function wait(ms,complete,canceled){
		var timeout = {
			timer: setTimeout(complete,ms),
			clear: function(){
				clearTimeout(timeout.timer);
				canceled();
			}
		};
		return timeout;
	}

	function show(id){
		var deferred = $.Deferred(),
			slideOut = slideOuts[id];

		if(!slideOut.element){
			render(id);
			slideOut.scrollY = window.scrollY;
			slideOut.active = wait(30,function(){
				slideOut.element.addClass('slide-open');
				slideOut.boundTo.addClass('push-left');
				slideOut.active = wait(duration,function(){
					slideOut.active = undefined;
					deferred.resolve();
					slideOut.element.addClass('slide-opened');
				},deferred.reject);
				slideOut.callListeners('show');
				
			},deferred.reject);
			
		}
		else {
			deferred.resolve();
		}

		return deferred;
	}

	function hide(id){
		var deferred = $.Deferred(),
			slideOut = slideOuts[id];
		if(slideOut.element){
			slideOut.element.removeClass('slide-open').removeClass('slide-opened');
			slideOut.boundTo.removeClass('push-left');
			slideOut.active = wait(duration,function(){
				remove(id);
				slideOut.active = slideOut.element = undefined;
				deferred.resolve();
				window.location.hash = '';
			},deferred.reject);
			slideOut.callListeners('hide');
		}
		else {
			deferred.resolve();
		}

		return deferred;

	}

	function render(id){
		var slideOut = slideOuts[id];
		slideOut.element = $('<comp-slideout><a class="close">Hide</a></comp-slideout>');
		slideOut.element.append(slideOut.content);
		slideOut.element.on('click','a.close',function(e){
			e.preventDefault();
			let {scrollY} = slideOut;
			hide(id).done( () => wait(30,() => window.scrollTo({top:scrollY})));
		});
		slideOut.boundTo.prepend(slideOut.element);
	}

	function remove(id){
		slideOuts[id].element.remove();
		slideOuts[id].callListeners('hide');
	}

	function hideAll(){
		var deferred = $.Deferred();
		$.when.apply($,Object.keys(slideOuts).reduce(function(array,current){
			return array.concat(hide(current));
		},[])).done(deferred.resolve);
		return deferred;
	}


	$.fn.slideOut = function() {
		var _id = (Math.random() * 100000) + '-' + Date.now(),
			boundTo = this,
			listeners = {
				hide: [],
				show: []
			};

		var exp = {
			destroy: function(){
				var deferred = $.Deferred();
				hide(_id).done(function(){
					delete slideOuts[_id];
					deferred.resolve();
				});
				return deferred;
			},
			show: function(){
				var deferred = $.Deferred();
				if(!slideOuts[_id].element){
					// Hide all others first
					hideAll().done(function(){
						show(_id).done(deferred.resolve);
					});
				}
				else {
					deferred.resolve();
				}
				return deferred;
			},
			hide: function(){
				var deferred = $.Deferred();
				hide(_id).done(()=>{
					deferred.resolve();
				});
				return deferred;
			},
			content: function(content){
				slideOuts[_id].content = content;
				return exp;
			},
			on(ev,cb){
				listeners[ev].push(cb);
			}
		};

		slideOuts[_id] = {
			content: '',
			active: undefined,
			element: undefined,
			boundTo: boundTo,
			callListeners(ev){
				listeners[ev].forEach( cb => cb() );
				listeners[ev] = [];
			}
		};

		return exp;
	};
 
}( jQuery ));