'use strict';
$(function(){
	$('form[ajax]').each(function(){
		var $self = $(this),
			packageJSON = $self.is('[json]');

		$self.on('submit',function(e){
			var errors = [];
			e.preventDefault();
			$(this).find('[required]:not(:disabled)').each(function(){
				var $input = $(this),
					val = $input.val();
				if(!val || val.trim() === ''){
					errors.push(($input.attr('placeholder') || $input.attr('name') ) + ' is required.');
					return;
				}
				switch($input.attr('type')){
					case 'email':
						console.log(val,val.split('@'));
						if(val.split('@').length !== 2){
							errors.push('Please enter a valid email address.');
						}
						break;
				}
			});

			$self.find('.errors').html(errors.map(function(error){
				return $.htm('p',error);
			}).join(''));

			if(errors.length > 0){
				return;
			}

            var lead = packageJSON ? JSON.stringify({
                Json: JSON.stringify($self.serializeObject())
            }) : $self.serialize();
            
			$self.addClass('loading');
            $.ajax({
                type: 'POST',
                url: $self.attr('ajax'),
                processData: false,
                dataType: 'json',
                contentType: packageJSON ? 'text/plain;utf-8': 'application/x-www-form-urlencoded; charset=UTF-8',
                data: lead,
                beforeSend: function () {
                    $self.addClass('thankyou');
                }
            }).done(function (response) {
				console.log('response:', response);
            }).fail(function (err) {
				console.log(err);
                console.log(arguments);
                $self.removeClass('loading').removeClass('thankyou').addClass('error');
			});
		});

	});

	$('[if-checked-enable]').each(function(){
		var $field = $(this),
			$target = $($field.attr('if-checked-enable'));

		$('[name="'+$field.attr('name')+'"]').on('change',enable);

		enable();

		function enable(){
			$target.prop( 'disabled', !$field.is(':checked') );
		}
	});

});