'use strict';
(window.jwplayer || {}).key = 'Hq5QblVCma5R2VN87ARXqwm/oupf3YJr8Y4Tbw==';
(function ( $ ) {
	var iOS = ( navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false );
	$.tb = {
		rootURL: window.location.hostname.indexOf('.dev') !== -1 ? 'https://corpcomm-dev.azurewebsites.net/umbraco/api' : '/umbraco/api',
		iOS: iOS,
		mapURL: iOS ? 'http://maps.apple.com/?daddr=' : 'https://www.google.com/maps/?q=',
		jwplayerId: 'sHGOoMEW',
		shareTemplates: [
			{
				name: 'twitter',
				template({link}){
					return `https://twitter.com/intent/tweet/?text=${encodeURIComponent(link)}`;
				}
			},
			{
				name: 'facebook',
				template({link}){
					return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`;
				}
			},
			{
				name: 'linkedin',
				template({link}){
					return `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(link)}`;
				}
			},
			{
				name: 'email',
				template({title = '',link}){
					return `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(link)}`;
				}
			},
		]
	};
}( jQuery ));