'use strict';
$(function(){
	$('comp-feature').each(function(){
		var $feature = $(this),
			$backgrounds = $feature.find('comp-background'),
			$slick,
			slickOptions = {
				dots: true,
				infinite: true,
				arrows: true,
				speed: 300,
				slidesToShow: 1,
				centerMode: true,
				variableWidth: true,
				//adaptiveHeight: true,
				slide: 'comp-background'
			};

		if($backgrounds.length === 1){
			$feature.on('click','comp-background',function(){
				console.log($(this).closest('comp-feature').find('a[role="button"]'));
				var url = $(this).closest('comp-feature').find('a[role="button"]').attr('href');
				if(url){
					window.location.href = url;
				}
			});
		}
		else if ($backgrounds.length > 1) {
			$slick = $backgrounds.closest('comp-container');
			$slick.slick(slickOptions);
			let $list = $feature.find('.slick-list');
				$list.css('height',$list.parent().height());

			$(window).on('resize',throttle(()=>{
				$slick.addClass('resizing');
				setTimeout(()=>{
					$slick.slick('unslick');
					setTimeout(()=>{
						$slick.slick(slickOptions);
						let $list = $feature.find('.slick-list');
						$list.css('height',$list.parent().height());
						setTimeout(()=>{
							$slick.removeClass('resizing');
						},500);
					},10);
				},200);
				
			},100));
		}
		
	});

	function throttle(func, wait, options) {
	  var context, args, result;
	  var timeout = null;
	  var previous = 0;
	  if (!options) options = {};
	  var later = function() {
	    previous = options.leading === false ? 0 : Date.now();
	    timeout = null;
	    result = func.apply(context, args);
	    if (!timeout) context = args = null;
	  };
	  return function() {
	    var now = Date.now();
	    if (!previous && options.leading === false) previous = now;
	    var remaining = wait - (now - previous);
	    context = this;
	    args = arguments;
	    if (remaining <= 0 || remaining > wait) {
	      if (timeout) {
	        clearTimeout(timeout);
	        timeout = null;
	      }
	      previous = now;
	      result = func.apply(context, args);
	      if (!timeout) context = args = null;
	    } else if (!timeout && options.trailing !== false) {
	      timeout = setTimeout(later, remaining);
	    }
	    return result;
	  };
	};
});