'use strict';
$(function(){
	var h = $.htm;
    
	var autoload = window.location.hash !== '' ? window.location.hash.split('#')[1] : false;

	function formatDate(date){
		var d = new Date(date);
		return h('span class="month"',$.MonthPicker.i18n.months[d.getMonth()])
				('span class="day"',d.getDate());
	}

	function daysInMonth(month,year) {
		return new Date(year, month, 0).getDate();
	}

	function getRange(str){
		var month, year;
		if(!str){
			return '2001/01/01-'+((new Date()).getFullYear() + 1)+'/01/01';
		} 
		month = str.split('/')[0];
		year = str.split('/')[1];

		return year + '/' + month + '/01-'+ year + '/' + month + '/'+daysInMonth(month,year);
	}

	$('comp-press:not([internal])').each(function(){
		var press = $(this),
            count = press.attr('count'),
            endpoint = press.attr('endpoint'),
            resourcePath = press.attr('resourcepath'),
			defaultBrand = press.attr('default'),
			date = press.find('input[name="daterange"]'),
			search = press.find('input[name="search"]'),
			select = press.find('select'),
			container = press.find('comp-container'),
			pageNumber = press.attr('page'),
			slideOut = $('body').slideOut();

		container.on('click','button.load-more',function(){
			refreshView(parseInt($(this).attr('page')));
		});

		function refreshView(pageNumber){
			if(typeof pageNumber !== 'number'){
				container.html('');
			}
			else {
				container.find('button.load-more').remove();
			}
            container.addClass('loading');
            
			$.ajax({
				url: endpoint,
				dataType: 'jsonp',
				jsonpCallback: 'JsonReturn',
				data: {
					brandname: select[0] ? select.val() : defaultBrand,
					pagesize: count,
					pagenumber: typeof pageNumber === 'number' ? pageNumber : 1,
					daterange: getRange(date[0] ? date.val() : ''),
					requesttype: 'pressrelease',
					search: search[0] ? search.val() : ''
				}
			}).done(function(data){
				// https://cmspublish-dtna.prd.freightliner.com/bin/dtna/json/service?callback=JsonReturn&requesttype=pressrelease&nodename=western-star-partners-with-namco-to-2016-02-29&_=1456936893028
				container[typeof pageNumber === 'number' ? 'append' : 'html'](data.resultHolder.reduce(function (html, item) {
					return html + h('div data-node-name="' + item.pressReleaseNodeName + '"',
						h('span class="date"', formatDate(item.pressReleaseDate))
							('span class="title"', item.pressReleaseTitle));
				}, '') + (data.totalResults > data.pageNumber * data.pageSize ? h('button class="load-more btn" page="' + (data.pageNumber + 1) + '"', 'Load More') + '' : '') || '<h3>' + container.attr('no-results') + '</h3>').removeClass('loading');
			});
		}

		function loadItem(nodeName,component,after){
			if(component){
				component.addClass('loading');
			}
			$.ajax({
				url: endpoint,
				dataType: 'jsonp',
				jsonpCallback: 'JsonReturn',
				data: {
					nodename: nodeName,
					requesttype: 'pressrelease'
				}
			}).done(function(data){
				var results = data.detailQueryResultHolder;
				if(component){
					component.removeClass('loading');
				}
				if(results && results.pressReleaseArticle){
					window.location.hash = nodeName;
					slideOut.content(
						'<inner-content><h2>'+
							results.pressReleaseTitle+
						'</h2><div class="date">'+
							results.pressReleaseDate+
						'</div>'+
							results.pressReleaseArticle+
						(results.downloadImages.length ? '<div class="images"><h4>Images:</h4>'+
							results.downloadImages.reduce(function(str,cur){ 
								return str + '<div class="image"><img src="'+resourcePath+cur.lowResPath+'"><h5>'+cur.cutline+'</h5><a href="'+resourcePath+cur.lowResPath+'" download>Download Lo-Res File</a><a href="'+resourcePath+cur.mediumResPath+'" download>Download Med-Res File</a><a href="'+resourcePath+cur.highResPath+'" download>Download High-Res File</a></div>';
							},'')+
						'</div>' : '')+
						'</inner-content>').show();
				}
				if(after){
					after();
				}
			});
		}

		if(date[0]){
			date.MonthPicker({
				ShowIcon: false, 
				OnAfterChooseMonth: refreshView,
				MaxMonth: '0m',
				MinMonth: new Date('Jan 1, 2011')
			});
		}
		if(select[0]){
			select.on('change', refreshView);
		}

		press.on('click','button[name="more"]',refreshView);

		press.find('input[type="text"]').on('keypress',function (e) {
			if(e.which === 13){
				refreshView();
				return false;  
			}
		});

		container.on('click','[data-node-name]',function(){
			var n = $(this),
				nodeName = n.attr('data-node-name');
			
			loadItem(nodeName,n);
		});
		
		if(autoload){
			container.html('').addClass('loading');
			loadItem(autoload,false,refreshView);
		}
		else {
			refreshView();
		}

		
	});
});	