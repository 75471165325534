const markers = [5,10,25,50,75]; //adjust these values if you want different progress reports

//Function call to push to dataLayer, passing in current scope of this, 
// the eventType value and the eventInteraction value for the DL push
function eventToDataLayer (thisObject, eventType, eventInteraction) {
    var eventName;
    if (thisObject.getPlaylistItem().title) {
        eventName = thisObject.getPlaylistItem().title;
    } else {
        eventName = 'not set';
    }
    //TODO: Be sure to create additional Data Layer Variables to reference the keys in our dataLayer model below
    //      You can use these additional variables as custom dimensions or in your GA Event Tags as labels
    //      This model can be extended following the JW Player JS API reference guide to meet your needs
    dataLayer.push({
        event: eventType,
        jwp_player_id: thisObject.id,
        jwp_video_name: eventName, // DL Variable: JWPlayer - DL - Video Name
        jwp_interaction: eventInteraction, // DL Variable: JWPlayer - DL - Interaction
        jwp_video_url: thisObject.getPlaylistItem().file,
        jwp_duration: thisObject.getDuration(),
        jwp_width: thisObject.getWidth(),
        jwp_height: thisObject.getHeight(),
        jwp_position: thisObject.getPosition(),
        jwp_resolutions: [].map.call(thisObject.getQualityLevels(), function(obj) { return obj.label;}),
        jwp_volume: thisObject.getVolume(),
        jwp_player_type: thisObject.renderingMode
    });
}

export function playerTrack(player){
    var playersMarkers = [];

    //TODO: You can add other API event listeners like bufferChange, buffer, idle, seek, seeked, mute, etc...
    //      These are some 
    player.on('setupError',
        function(e) {
            eventToDataLayer (this, 'setup-error', e.message); 
        }
    );
    player.on('play',
                function(e){
                    var playResume ;
                    if (this.getPosition() < 2) {
                        playResume = 'Play';
                    } else {
                        playResume = 'Resume';
                    }
                    eventToDataLayer (this, 'playback-play', playResume);
                }
            );
    player.on('pause',
        function(e){
            eventToDataLayer (this, 'playback-pause', 'Pause');
        }
    );
    player.on('complete',
        function(e){
            eventToDataLayer (this, 'playback-complete', 'Complete');
        }
    );
    player.on('time',
        function(e){
            var percentPlayed = Math.floor(e.position*100/e.duration);
            if(markers.indexOf(percentPlayed)>-1 && playersMarkers.indexOf(percentPlayed)==-1)
                {
                playersMarkers.push(percentPlayed);
                eventToDataLayer (this, 'Progress', 'Progress ' + percentPlayed + '%');
            }
        }
        );
    player.on('playlistItem',
        function(e) {
            playersMarkers = [];
            eventToDataLayer (this, 'playlist-item','Playlist item number: ' + (e.index+1));
        }
    );
    player.on('error',
        function(e){
            eventToDataLayer(this, 'playback-error', e.message);
        }
    );
}