'use strict';
$(function() {
	$('a[rel="VideoButton"]').on('click',function(e){
		e.preventDefault();
		var slideOut = $('body').slideOut();
		slideOut.content('<comp-background><iframe src="'+this.href+'" width="480" height="270" frameborder="0" scrolling="auto" allowfullscreen></iframe></comp-background>').show();
	});
	$('a[rel="JWVideoButton"]').on('click', function (e) {
		e.preventDefault();
		var slideOut = $('body').slideOut();
		slideOut.content('<comp-container napa-intro="" text-position="Left" video="" class="inview" style="max-width:100%;background-color:#000000;"><video video="" autobuffer="" autoplay="" playsinline="" loop="" controls="" id="html5_video_se4vpig0oe9"><source id="mp4" src="https://corpcomm.blob.core.windows.net/media/video/corp-home-hero.mp4" type="video/mp4"></video></comp-container>').show();
	});
});