'use strict';
(function($){
	let password = ($('head > meta[challenge]') || $('<meta></meta>')).attr('challenge');
	
	if(password){
		let $form = $('<form challenge><label><p>Enter Page Password to Continue</p><input type="password" /></label><button>Continue to Page</button><span class="error" style="display: none;">Incorrect Password</label></form>');
		$form.on('submit',(e) => {
			e.preventDefault();
			if($form.find('input').val().trim() === password){
				$('html').attr('challenge',null);
				setTimeout(()=>$form.remove(),2000);
				$form.find('.error').hide(200);
				$('comp-timeline').masonry();
			}
			else {
				$form.find('.error').show(200);
			}
		});
		$('body').append($form);
	}
})(jQuery);