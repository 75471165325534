'use strict';
$(function(){
	var h = $.htm;
	$('comp-timer').each(function(){
		var $container = $(this),
			$days = timeCard('days'),
			$hours = timeCard('hours'),
			$mins = timeCard('minutes'),
			$secs = timeCard('seconds');


		$container.append($days,$hours,$mins,$secs);

		counter(parseInt($container.attr('end')) - parseInt($container.attr('now'))).onTick(function(days,hours,mins,secs){
			$days.set(days);
			$hours.set(hours);
			$mins.set(mins);
			$secs.set(secs);
		});

	});

	function counter(timeLeft){

		var ticks = [],
			// last = Date.now(),
			ticker = setInterval(function(){
				var now = Date.now(),
					delta = 1000, //now - last, not as accurate but real delta could jump as the timing is off.
					totalSeconds,
					days,
					hours,
					mins,
					secs;
				// last = now;
				timeLeft -= delta;
				if(timeLeft < 0){
					timeLeft = 0;
					clearInterval(ticker);
					days = hours = mins = secs = 0;
				}
				else {
					totalSeconds = timeLeft / 1000;
					days = Math.floor(totalSeconds / 86400); // 86400 = 24 hours * 60 minutes * 60 seconds per day
					hours = Math.floor((totalSeconds % 86400) / 3600); // 3600 = 60 minutes * 60 seconds per day
					mins = Math.floor((totalSeconds % 3600) / 60); // 60 = 60 seconds per minute
					secs = Math.floor((totalSeconds % 60)); // 60 = 60 seconds per minute
				}
				ticks.forEach(function(cb){
					cb(days,hours,mins,secs);
				});
			},1000);


		

		return {
			onTick: function(cb){
				ticks.push(cb);
			}
		}
	}

	function timeCard(label){
		var $card = h('div count="00"',label).$;

		$card.set = function(n){
			$card.attr('count',twoDigit(n));
		};

		return $card;
	}

	function twoDigit(n){
		return n > 9 ? '' + n: '0' + n;
	}
});