'use strict';
$(function(){
	var h = $.htm;
    
	//var autoload = window.location.hash !== '' ? window.location.hash.split('#')[1] : false;

	function formatDate(date){
		var d = new Date(date);
		return h('span class="month"',$.MonthPicker.i18n.months[d.getMonth()])
				('span class="day"',d.getDate());
	}

	function daysInMonth(month,year) {
		return new Date(year, month, 0).getDate();
	}

	function getRange(str){
		var month, year;
		if(!str){
			return '2001/01/01-'+((new Date()).getFullYear() + 1)+'/01/01';
		} 
		month = str.split('/')[0];
		year = str.split('/')[1];

		return year + '/' + month + '/01-'+ year + '/' + month + '/'+daysInMonth(month,year);
	}

	$('comp-press[internal]').each(function(){
        var press = $(this),
            count = press.attr('count'),
            endpoint = press.attr('endpoint'),
            resourcePath = press.attr('resourcepath'),
            defaultBrand = press.attr('default'),
            tags = press.attr('tags') || '',
            date = press.find('input[name="daterange"]'),
            search = press.find('input[name="search"]'),
            select = press.find('select'),
            container = press.find('comp-container');

		container.on('click','button.load-more',function(){
			refreshView(parseInt($(this).attr('page')));
		});

		function refreshView(pageNumber){
			if(typeof pageNumber !== 'number'){
				container.html('');
			}
			else {
				container.find('button.load-more').remove();
			}
            container.addClass('loading');
			
			$.ajax({
				url: endpoint,
				data: {
					brandname: select[0] ? select.val() : defaultBrand,
					pagesize: count,
					pagenumber: typeof pageNumber === 'number' ? pageNumber : 1,
					daterange: getRange(date[0] ? date.val() : ''),
					tags,
					requesttype: 'pressrelease',
					search: search[0] ? search.val() : ''
				}
			}).done(function (data) {
				
				let pressHtml = typeof pageNumber === 'number'
					? container.html()
					: '';

				if (data.resultHolder.length > 0) {

					for (let i = 0, l = data.resultHolder.length; i < l; i++) {

						const item = data.resultHolder[i];

						const backgroundUrl = item.image
							? imgIxSrc(item.image)
							: '/images/news.png';

						let downloadLink = '';
						if (item.downloadCount > 0) {
							const imgTxt = item.downloadCount === 1 ? 'Image' : 'Images';
							downloadLink = `<a href="/PressDetail/${item.nodeName}#downloads" class="download">Download ${item.downloadCount} ${imgTxt}</a>`;
						}

						let html = `
							<div card>
								<header style="background-image: url(${backgroundUrl});"></header>
								<section>
									<h3 class="title">${item.title}</h3>
									<time>${item.date}</time>
									<div>${item.summary}</div>
								</section>
								<footer>
									<a href="/PressDetail/${item.nodeName}">Read More</a>
									${downloadLink}
								</footer>
							</div>
						`;

						pressHtml += html;
					}

					if (data.totalResults > data.pageNumber * data.pageSize) {
						const pageNumber = data.pageNumber + 1;

						pressHtml += `<button class="load-more btn" page="${pageNumber}">Load More</button>`;
					}

				} else {
					pressHtml = `<h3>${container.attr('no-results') }</h3>`;
				}

				container.html(() => pressHtml);

				container.removeClass('loading');

			});
		}

		function imgIxSrc({src,width,height}){
			let splitter = '/press-releases/',
				aspectRaw = width / height,
				aspect = isNaN(aspectRaw) ? 1 : aspectRaw,
				options = aspect < 1.3 ? '&fit=fill&fill=blur' : '&fit=crop&crop=entropy';
			return src.indexOf(splitter) !== -1 ? 
				'https://dtna-content.imgix.net' + splitter + (src + 'x').split(splitter)[1].split('/jcr:content/')[0] + '?w=650&h=456' + options : 
				src;
		}

		if(date[0]){
			date.MonthPicker({
				ShowIcon: false, 
				OnAfterChooseMonth: refreshView,
				MaxMonth: '0m',
				MinMonth: new Date('Jan 1, 2011')
			});
		}
		if(select[0]){
			select.on('change', refreshView);
		}

		press.on('click','button[name="more"]',refreshView);

		press.find('input[type="text"]').on('keypress',function (e) {
			if(e.which === 13){
				refreshView();
				return false;  
			}
		});

		container.on('click','[data-node-name]',function(){
			var n = $(this),
				nodeName = n.attr('data-node-name');		
		});
		
		refreshView();
		
	});
});	