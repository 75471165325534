'use strict';
$(function(){
	function flipSocial(social){
		return function(){
			if(social.attr('over') !== 'over'){
				social.find('comp-container').each(function(i){
					var n = $(this);
					setTimeout(function(){
						n.toggleClass('flipped');
					},i*200);
				});
			}
		};
	}

	$('comp-social').each(function(){
		$(this).hover(function(){
			$(this).attr('over','over');
		},function(){
			$(this).attr('over','');
		});
		setInterval(flipSocial($(this)),5000);
	});
});