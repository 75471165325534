'use strict';
$(function(){
	// $('body').on('click','.search-active',function(){
	// 	$(this).find('input[type="text"]').focus();
	// });
	// $('body').on('click','.search-active .close',function(){
	// 	console.log('close');
	// 	$(this).closest('.search-active').removeClass('search-active');
	// });
	// $('[data-action="search"]').on('click',function(e){
	// 	e.preventDefault();
	// 	$(this).closest('header').addClass('search-active').find('input[type="search"]').focus().attr('placeholder','Search');
	// 	//searchControle.draw($($(this).attr('data-target')).attr('id'),drawOptions);
	// });
	[...document.querySelectorAll('#page-search-form')].forEach( node => {
		node.querySelector('input').focus();
	});
});