'use strict';

let sliders,
    sliderSelectors = [
        'slide-cards',
    ];

$(function(){
	sliders = [...document.querySelectorAll(sliderSelectors.join(', '))].map( (component, index) => {
        let holder = component.querySelector('section'),
            nav = document.createElement('nav'),
            pageControls = document.createElement('page-controls'),
            arrows = document.createElement('section'),
            larrow = document.createElement('span'),
            rarrow = document.createElement('span'),
            isScrolling,
            over;
        if(component.hasAttribute('pager')){
            arrows.appendChild(larrow);
            arrows.appendChild(rarrow);
            pageControls.appendChild(arrows);
        }
        pageControls.appendChild(nav);
        component.appendChild(pageControls);
        holder.classList.add('loaded');

        component.addEventListener('click', event => {
            let page = 
                event.target.matches('page-controls > section > span:first-of-type') ? 'prev' :
                event.target.matches('page-controls > section > span:last-of-type') ? 'next' :
                event.target.getAttribute('page');
            if(page){
                return goToPage({page,index})
            }
        }, false);

        holder.addEventListener('scroll', event => {
            clearTimeout( isScrolling );
            isScrolling = setTimeout(function() {
                goToClosest(index);
            }, 66);
        },false);

        if(component.hasAttribute('auto')){
            component.addEventListener('mouseenter',() => over = true);
            component.addEventListener('mouseleave',() => over = false);
            setInterval(() => !over ? goToPage({page:'next',index}) : true, 3000);
        }

        return {
            component,
            holder,
            cards: component.getElementsByTagName('a'),
            nav,
            current: 0,
            larrow,
            rarrow
        };
    });

    window.addEventListener('resize',checkOverflow);
    checkOverflow();
});

function goToClosest(index){
    let {holder,pages} = sliders[index],
        page = Math.min(Math.ceil(holder.scrollLeft / holder.offsetWidth), pages - 1);
    goToPage({page,index});
}

function goToPage({page,index}){
    let {holder,cards,nav,pages,current} = sliders[index],
        pageNum = 
        page === 'next' ? current + 1 : 
        page === 'prev' ? current - 1 :
        page;

    if(pageNum < 0){
        pageNum = pages - 1;
    }
    if(pageNum > pages - 1){
        pageNum = 0;
    }

    sliders[index].current = pageNum;

    holder.scroll({ top: 0, left: holder.offsetWidth * pageNum, behavior: 'smooth' });
    [...nav.getElementsByTagName('span')].forEach( (span, num) => {
        span.classList[num === pageNum ? 'add' : 'remove']('current');
    });

    return false;
}

function checkOverflow(){
    sliders.forEach(({holder,nav,pages,current,component},holderNum) => {
        let pageCount = Math.ceil(holder.scrollWidth / holder.offsetWidth);
        if(pages !== pageCount){
            sliders[holderNum].pages = pageCount;
            component.setAttribute('pages',pageCount);
            nav.innerHTML = '';
            if(pageCount > 1){
                for (let index = 0; index < pageCount; index++) {
                    let navItem = document.createElement('span');
                    navItem.setAttribute('page',index);
                    if(index === current){
                        navItem.classList.add('current');
                    }
                    nav.appendChild(navItem);
                }
            } 
            goToClosest(holderNum);       
        }
    });
}