"use strict";

const carousels = document.querySelectorAll("[data-carousel-slick]");
/**
 * @type jQuery
 */
$(function () {
	if (!carousels.length) return;
	carousels.forEach((elem) => {
		const slider = elem.querySelector(
			"[data-carousel-slick--slider]"
		);
		const leftButton = elem.querySelector("[data-scroll-left]");
		const rightButton = elem.querySelector("[data-scroll-right");
		const currentSlideLabel = elem.querySelector("[data-current-slide");
		const totalSlidesLabel = elem.querySelector("[data-total-slides]");

		if (
			!slider ||
			!leftButton ||
			!rightButton ||
			!currentSlideLabel ||
			!totalSlidesLabel
		) {
			return;
		}

		totalSlidesLabel.innerHTML = `${slider.children.length}`.padStart(2, "0");
		currentSlideLabel.innerHTML = `${1}`.padStart(2, "0");

		$(slider).slick({
			infinite: true,
			speed: 300,
			slidesToShow: 1,
			prevArrow: leftButton,
			nextArrow: rightButton,
			adaptiveHeight: true
		});
		$(slider).on("afterChange", (slick, currentSlide, currentIndex) => {
			currentSlideLabel.innerHTML = `${currentIndex + 1}`.padStart(2, "0");
		});
	});
});
