'use strict';
$(function(){
	function stripVowelAccent(str) {
		return [
			['A', /[\xC0-\xC2]/g], 
			['a', /[\xE0-\xE2]/g], 
			['E', /[\xC8-\xCA]/g], 
			['e', /[\xE8-\xEB]/g], 
			['I', /[\xCC-\xCE]/g], 
			['i', /[\xEC-\xEE]/g], 
			['O', /[\xD2-\xD4]/g], 
			['o', /[\xF2-\xF4]/g], 
			['U', /[\xD9-\xDB]/g], 
			['u', /[\xF9-\xFB]/g]
		].reduce(function(str,cur){
			return str.replace(cur[1], cur[0]);
		},str);
	}

	function highlight(node, word, doc) {
		var pn,
			nv,
			ni,
			before,
			docWordVal,
			after,
			hiwordtext,
			hiword,
			tempNodeVal,
			tempWordVal;
		doc = typeof (doc) !== 'undefined' ? doc : document;
		// Iterate into this nodes childNodes
		if (node.hasChildNodes) {
			var hi_cn;
			for (hi_cn = 0; hi_cn < node.childNodes.length; hi_cn++) {
				highlight(node.childNodes[hi_cn], word, doc);
			}
		}

		// And do this node itself
		if (node.nodeType === 3) { // text node
			tempNodeVal = stripVowelAccent(node.nodeValue.toLowerCase());
			tempWordVal = stripVowelAccent(word.toLowerCase());
			if (tempNodeVal.indexOf(tempWordVal) !== -1) {
				pn = node.parentNode;
				if (pn.className !== "highlight") {
					// word has not already been highlighted!
					nv = node.nodeValue;
					ni = tempNodeVal.indexOf(tempWordVal);
					// Create a load of replacement nodes
					before = doc.createTextNode(nv.substr(0, ni));
					docWordVal = nv.substr(ni, word.length);
					after = doc.createTextNode(nv.substr(ni + word.length));
					hiwordtext = doc.createTextNode(docWordVal);
					hiword = doc.createElement("span");
					hiword.className = "highlight";
					hiword.appendChild(hiwordtext);
					pn.insertBefore(before, node);
					pn.insertBefore(hiword, node);
					pn.insertBefore(after, node);
					pn.removeChild(node);
				}
			}
		}
	}

	function unhighlight(node) {
		var pn,
			nextSib,
			prevSib;
		// Iterate into this nodes childNodes
		if (node.hasChildNodes) {
			var hi_cn;
			for (hi_cn = 0; hi_cn < node.childNodes.length; hi_cn++) {
				unhighlight(node.childNodes[hi_cn]);
			}
		}

		// And do this node itself
		if (node.nodeType === 3) { // text node
			pn = node.parentNode;
			if (pn.className === "highlight") {
				prevSib = pn.previousSibling;
				nextSib = pn.nextSibling;
				nextSib.nodeValue = prevSib.nodeValue + node.nodeValue + nextSib.nodeValue;
				prevSib.nodeValue = '';
				node.nodeValue = '';
				$(pn).remove();
			}
		}
	}

	function narrow() {
		var n = $(this), 
			val = n.val().toLowerCase(), 
			data = $(n.closest('comp-faqs').find('> comp-container')), 
			showing = data.size(), 
			hid = false;

		data.each(function () {
			var d = $(this);
			unhighlight(this);
			if (val === '' || val.length < 3 || d.text().toLowerCase().search(val) !== -1) {
				d.show();
				if (val !== '' && val.length > 2) {
					highlight(this,val);
				}
			}
			else {
				d.hide();
				showing--;
				hid = true;
			}
		});

		if (showing < 1) {
			// Show None Found Message Here
		}
		else {
			// Hide None Found Message Here
		}
	}

	$('comp-faqs').each(function(){
		var faqSect = $(this),
			form = $('<form><input type="text" placeholder="Search FAQs..."></form>');
		form.on('submit',function(e){
			e.preventDefault();
		});
		form.find('input').on('keyup',narrow);
		faqSect.prepend(form);
	});
});