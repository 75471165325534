'use strict';
(function($){

	function resizeText($el,inc,min,max) {
		var newSize = (parseInt($el.css('font-size').slice(0, -2)) + inc);
		if(newSize < min){
			newSize = min;
		}
		if(newSize > max){
			newSize = max;
		}
		$el.css('font-size', newSize + 'px');
		return newSize;
	}

	$.fn.fitText = function(min = 10,max = 200,fitselector = '[fit-text]'){
		this.each(function(){
			var $self = $(this),
				$fit = $self.find(fitselector),
				localMax;

			if(!$fit[0]){
				$fit = $self;
			}	

			if(max === 'auto'){
				if($fit.data('base-font-size')){
					localMax = $fit.data('base-font-size');
				}
				else {
					localMax = parseInt($fit.css('font-size').slice(0, -2));
					$fit.data('base-font-size',localMax);
				}
			}
			else {
				localMax = max;
			}

			// Size up if available
			while (($self[0].scrollHeight <= $self[0].offsetHeight || $self[0].scrollWidth <= $self[0].offsetWidth) && resizeText($fit,1,min,localMax) < localMax) {}

			// Size down
			while (($self[0].scrollHeight > $self[0].offsetHeight || $self[0].scrollWidth > $self[0].offsetWidth) && resizeText($fit,-1,min,localMax) > min) {}

		});

		return this;
	};
})(jQuery);

/*!	
* FitText.js 1.0 jQuery free version
*
* Copyright 2011, Dave Rupert http://daverupert.com 
* Released under the WTFPL license 
* http://sam.zoy.org/wtfpl/
* Modified by Slawomir Kolodziej http://slawekk.info
*
* Date: Tue Aug 09 2011 10:45:54 GMT+0200 (CEST)
*/
(function () {

	var addEvent = function (el, type, fn) {
		if (el.addEventListener)
			el.addEventListener(type, fn, false);
		else
			el.attachEvent('on' + type, fn);
	};

	var extend = function (obj, ext) {
		for (var key in ext)
			if (ext.hasOwnProperty(key))
				obj[key] = ext[key];
		return obj;
	};

	window.fitText = function (el, kompressor, options) {

		var settings = extend({
			'minFontSize': -1 / 0,
			'maxFontSize': 1 / 0
		}, options);

		var fit = function (el) {
			var compressor = kompressor || 1;

			var resizer = function () {
				el.style.fontSize = Math.max(Math.min(el.clientWidth / (compressor * 10), parseFloat(settings.maxFontSize)), parseFloat(settings.minFontSize)) + 'px';
			};

			// Call once to set.
			resizer();

			// Bind events
			// If you have any js library which support Events, replace this part
			// and remove addEvent function (or use original jQuery version)
			addEvent(window, 'resize', resizer);
			addEvent(window, 'orientationchange', resizer);
		};

		if (el.length)
			for (var i = 0; i < el.length; i++)
				fit(el[i]);
		else
			fit(el);

		// return set of elements
		return el;
	};
})();