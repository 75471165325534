'use strict';
$(function () {
    [...document.querySelectorAll('form[contact]')].forEach( form => {
        let customerService = form.querySelector('#customerServiceContainer'),
            submit = form.querySelector('button[type="submit"]');

        form.querySelector('[name="Topic"]').addEventListener('change',({target: {value}}) => {
            const showMessage = parseInt(value) === 2;
            customerService.style.display = showMessage ? 'block' : 'none';
            [...form.querySelectorAll('input, select:not([name="Topic"]), button, textarea')].forEach(input => input[showMessage ? 'setAttribute' : 'removeAttribute']('disabled', 'disabled'))
        });

        form.addEventListener('submit', e => {
            let captchaKey = '';
            e.preventDefault();
            [...document.querySelectorAll('script[src*="recaptcha"][key]')].forEach(script => captchaKey = script.getAttribute('key'));
            submit.setAttribute('disabled',true);

            if(captchaKey){
                grecaptcha.ready(() => {
					grecaptcha
                    .execute(captchaKey, {action: 'submit'})
                    .then(handleFormSubmit)
                    .catch(console.log);
				});	
                return;
            }
            handleFormSubmit();
            return;
        });
        $.fn.serializeObject = function () {
            var self = this,
                json = {},
                push_counters = {},
                patterns = {
                    "validate": /^[a-zA-Z][a-zA-Z0-9_]*(?:\[(?:\d*|[a-zA-Z0-9_]+)\])*$/,
                    "key": /[a-zA-Z0-9_]+|(?=\[\])/g,
                    "push": /^$/,
                    "fixed": /^\d+$/,
                    "named": /^[a-zA-Z0-9_]+$/
                };


            this.build = function (base, key, value) {
                base[key] = value;
                return base;
            };

            this.push_counter = function (key) {
                if (push_counters[key] === undefined) {
                    push_counters[key] = 0;
                }
                return push_counters[key]++;
            };

            $.each($(this).serializeArray(), function () {

                // skip invalid keys
                if (!patterns.validate.test(this.name)) {
                    return;
                }

                var k,
                    keys = this.name.match(patterns.key),
                    merge = this.value,
                    reverse_key = this.name;

                while ((k = keys.pop()) !== undefined) {

                    // adjust reverse_key
                    reverse_key = reverse_key.replace(new RegExp("\\[" + k + "\\]$"), '');

                    // push
                    if (k.match(patterns.push)) {
                        merge = self.build([], self.push_counter(reverse_key), merge);
                    }

                    // fixed
                    else if (k.match(patterns.fixed)) {
                        merge = self.build([], k, merge);
                    }

                    // named
                    else if (k.match(patterns.named)) {
                        merge = self.build({}, k, merge);
                    }
                }

                json = $.extend(true, json, merge);
            });

            return json;
        };

        function handleFormSubmit(token) {
            let submitModel = {...$(form).serializeObject(), token };
            $.ajax({
                type: "POST",
                url: form.getAttribute('action'),
                contentType: "application/json",
                data: JSON.stringify(submitModel)
            }).done(() => {
                let thanks = $('div[role="thanks"]');

                $('div[role="form-container"]').hide();
                thanks.show();
                submit.removeAttribute('disabled');
    
                $([document.documentElement, document.body]).animate({
                    scrollTop: thanks.offset().top
                }, 500);
                
            }).fail(() => submit.removeAttribute('disabled'));	
        }; 
    });
});