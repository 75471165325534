'use strict';
/* global ScrollMagic */
$(function () {
	var controller = new ScrollMagic.Controller();
	$('comp-container').each(function () {
		var n = $(this);
		(new ScrollMagic.Scene({triggerElement: this, triggerHook: 0.9})
		// trigger animation by adding a css class
		//.setClassToggle(this, "inview")
		.on("enter", function () {
			n.addClass('inview');
		})
		.addTo(controller));
	});
	$('.btn').each(function () {
		(new ScrollMagic.Scene({triggerElement: this, triggerHook: 0.9})
		// trigger animation by adding a css class
		.setClassToggle(this, "inview")
		.addTo(controller));
	});

	(function(){
		var prev = 0,
			head = $('body > header'),
			headHeight = parseInt(head.height()),
			headCur = parseInt(head.css('top'));
		(new ScrollMagic.Scene())
			.on('update',function(e){
				var delta = e.scrollPos - prev;
				prev = e.scrollPos;
				if(delta > -1){
					headCur -= delta;
					if(headCur < -headHeight){
						headCur = -headHeight;
					}
					head.css({
						transition: 'none',
						WebkitTransition: 'none',
						MozTransition: 'none'
					});
					head.css('top',headCur+'px');
					
				}
				else {
					headCur = 0;
					head.css({
						transition: '',
						WebkitTransition: '',
						MozTransition: ''
					});
					head.css('top','');
				}

				//console.log(e.target.controller().info("scrollDirection"),delta);
			})
			.addTo(controller);
	})();
	
});