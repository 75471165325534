'use strict';
$(function(){
	var closed = true,
		body = $('body'),
		nav = $('body > nav'),
		overflow,
		active = false;
	function toggleMenu(e) {
		e.preventDefault();
		e.stopPropagation();
		
		if(!active){
			active = true;
			if(e.handled !== true) {
				body.toggleClass('push-right',closed);
				$('body > nav').toggleClass('menu-open',closed);
				$('body > .menu-cover')[closed ? 'show' : 'hide'](400);
				closed = !closed;
				e.handled = true;
			} else {
				return false;
			}
			setTimeout(()=>{
				active = false;
			},500);			
		}

	}

	function openParent(el){
		var parent = el.closest('ul'),
			grandparent = parent.closest('li');

			if(parent[0]){
				parent.addClass('in').attr('aria-expanded','true').css('height','');
			}
			if(grandparent[0]){
				grandparent.addClass('open');
				grandparent.find('> a > .sub-toggle').addClass('open');
				openParent(grandparent);
			}
		
	}

	body.on('click touchstart','#site-menu-button, > .menu-cover',toggleMenu);

	$('body > nav a + ul').each(function(){
		$(this).addClass('sub-menu').addClass('collapse').parent().find('> a').append('<span class="sub-toggle"></span>');
	});

	$('body > nav').on('click touchstart','.sub-toggle',function(e){
		e.preventDefault();
		e.stopPropagation();
		e.stopImmediatePropagation();
		if(e.handled !== true) {
			$(this).toggleClass('open').closest('li').toggleClass('open').find('> ul').collapse('toggle');
			e.handled = true;
		} else {
			return false;
		}
	});

	$('body > nav').on('click touchstart','a',function(){
		$('body > nav a').removeClass('current');
		$(this).addClass('current');
	});

	$('body > nav a.current').each(function(){
		openParent($(this));
	});

	function checkOverflow() {
		if(nav.length > 0)
			overflow = nav[0].scrollHeight-nav.height();
	}

	$(window).on('load resize', checkOverflow);
	nav.on('scroll',checkOverflow);

	checkOverflow();

	nav.on('touchmove', function(e) {
		console.log('touch');
		if (overflow <= 0 || true) {
			console.log('prevent');
			//e.preventDefault();
			return true;
		}
    });

});