'use strict';

import {playerTrack} from "../_analytics.js";

$(function() {
    let globalPlaylist = [],
        activePlayer;
        
    $('asset-cards a[jwpid]:not([jwpid=""])').each(function(){
        let $element = $(this),
            loc = location.href,
            index = globalPlaylist.length,
            jwpid = $element.attr('jwpid'),
            shareURL = ($element.closest('asset-cards').attr('video-url') || '').replace('{jwpid}',jwpid);
        if (jwpid) {
            setupShare({$element,shareURL});
            globalPlaylist[index] = undefined;
            $.getJSON(`https://content.jwplatform.com/feeds/${jwpid}.json`).done(function (data) {
                globalPlaylist[index] = data.playlist[0];
                $element.on('click',function(e){
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    let slideOut = $('body').slideOut();
                    slideOut.on('hide',() => {
                        console.log('hide',activePlayer);
                        activePlayer.remove();
                        if(history.state && history.state.video) {
                            history.back();
                        }
                        window.onpopstate = false;
                    });
                    slideOut.on('show',()=>{
                        //console.log($(`video-container[jwpid="${jwpid}"] > div`));
                        let $container = $(`video-container[jwpid="${jwpid}"]`);
                        activePlayer = jwplayer($container.find('section > div')[0]);
                        //console.log(activePlayer);
                        loadVideos(index, globalPlaylist);
                        {   
                            let videoURL = globalPlaylist[index].sources.find(item => item.type === 'mp4').file,
                                videoURLEncoded = encodeURIComponent(videoURL);
                            $container.find('section > nav').html(`
                                <a href="${videoURL}" target="_blank" class="icon-download" download>Download</a>
                                <a href="mailto:?subject=Daimler Video&body=Video URL: ${videoURLEncoded}" class="icon-email" target="mailto-target" email>Email</a>
                            `);
                        }
                        if(shareURL.trim()){
                            history.pushState({'video':true}, 'Video', shareURL);
                        }
                    });
                    slideOut.content(`
                        <video-container jwpid="${jwpid}">
                            <section>
                                <div></div>
                                <nav></nav>
                            </section>
                        </video-container>`).show();

                    window.onpopstate = () => slideOut.hide();
                });
            }).fail(function (error) {
                console.log(error);
            });

        }
    });

    $('asset-cards a[href]:not([href=""])').each(function(){
        let assetSig = 'blob.core.windows.net/media',
            $element = $(this),
            href = $element.attr('href'),
            asset = href.indexOf(assetSig) !== -1 ? href.split(assetSig).pop() : false,
            thumb = ($element.find('img').attr('src') || '').split(assetSig).pop(),
            shareURL = asset ? `${location.origin}/asset?file=${escapePath(asset)}&thumb=${escapePath(thumb)}` : href;
        if(thumb.length > 0 && !$element.is('[jwpid]:not([jwpid=""])')){
            setupShare({$element,shareURL});
        }
    });

    function escapePath(path){
        return encodeURIComponent(path);
    }

    function setupShare({$element,shareURL}){
        let shareIcon = $('<icon-share title="Share"></icon-share>');
        $element.append(shareIcon);
        shareIcon.on('click',function(e){
            e.preventDefault();
            e.stopImmediatePropagation();
            let templates = $.tb.shareTemplates,
                title = $element.closest('a').text(),
                slideOut = $('body').slideOut();
                
            slideOut.on('hide',() => {
                if(history.state && history.state.share) {
                    history.back();
                }
                window.onpopstate = false;
            });
            slideOut.on('show',() => {
                $('comp-share form').on('submit',function(e){
                    e.preventDefault();
                    let $form = $(this);
                    $form.find('input').select();
                    document.execCommand("copy");
                });
                if(shareURL.trim()){
                    history.pushState({'share':true}, 'Share', shareURL);
                }
            });
            slideOut.content(`
                <comp-share><section>
                    <nav>
                        ${templates.map(({name,template}) => `<a href="${template({link:shareURL,title})}" target="_blank" class="icon-${name}"></a>`).join('')}
                    </nav>
                    <form><input type="text" value="${shareURL}"><button type="submit" class="btn">Copy</button></form>
                </section></comp-share>`).show();

            window.onpopstate = () => slideOut.hide();


            // $share.on('click','a.close',function(e){
            //     $share.addClass('hidden');
            //     setTimeout( () => $share.remove(), 500);
            // });
            // $('body').append($share);
            // setTimeout( () => $share.removeClass('hidden'), 20);
        });
    }

    function loadVideos(index, playlist) {
        setDefaultSource(playlist);
        setupActivePlayer(playlist);
        activePlayer.setCurrentQuality(0);
        playVideo(index);
    }
    
    function setupActivePlayer(playlist) {
		activePlayer.setup({
			width: 560,
			height: 315,
			autostart: false,
			pid: $.tb.jwplayerId,
			playlist: playlist
		});
	}

	function setDefaultSource(playlist) {
		playlist.forEach(function (item) {
			var sources = item.sources;
            sources.sort( function(a,b) {
                return a.width > b.width ? 1 : -1;
            }).reverse();
            (sources[0] || {}).default = true;
		});
	}

	function playVideo(index) {
		// For some reason autoplaying fails in some cases and in some browsers if there is not a timeout.
		setTimeout(() => {
			activePlayer.playlistItem(index);
			activePlayer.pause();
			setTimeout(() => {
				activePlayer.play();
			}, 0);
		}, 0);

		playerTrack(activePlayer);
    }
    
	// $('asset-cards a').on('click',function(e){
    //     let {href} = this;
    //     if(href.endsWith('.mp4')){
    //         e.preventDefault();
    //         var slideOut = $('body').slideOut();
            // slideOut.content(`
            //     <video-container>
            //         <video video autobuffer autoplay playsinline>
            //             <source id="mp4" src="${href}" type="video/mp4">
            //         </video>
            //     </video-container>
            // `).show();
    //     }		
	// });
});