import "./app/_htm.js";
import "./app/_ends-with.js";
import "./app/_serializeObject.js";
import "./app/_config.js";
import "./app/_slide-out.js";
//import "./app/_components.js";
import "./app/_site-nav.js";
import "./app/_scroll-anim.js";
import "./app/_fitText.js";
import "./app/_secure.js";
import "./app/components/_background.js";
import "./app/components/_social.js";
import "./app/components/_intro.js";
import "./app/components/_faqs.js";
import "./app/components/_press-releases.js";
import "./app/components/_press-releases-internal.js";
import "./app/components/_button-video.js";
import "./app/components/_feature.js";
import "./app/components/_form.js";
import "./app/components/_timer.js";
import "./app/components/_count.js";
import "./app/components/_search.js";
import "./app/components/_take-over.js";
import "./app/components/_contact-us.js";
import "./app/components/_asset-cards.js";
import "./app/components/_sliders.js";
import "./app/components/_justice.js";
import "./app/components/_timeline.js";
import "./app/components/_scroller.js";
import "./app/components/_carousel-slick.js";
import domReady from './utils/domReady';

/*const components = [];

domReady.then(() => components.forEach(
	([elements, loader]) => [...document.querySelectorAll(elements)].forEach(
		async (element, index) => (await loader()).default(element, index)
	)
));*/

