'use strict';
$(function(){
	// var scrollTimer;

	// function scrollToFirst(sel,adjust){
	// 	clearTimeout(scrollTimer);
	// 	scrollTimer = setTimeout(function(){
	// 		var w = $('html, body'),
	// 			i = $(sel),
	// 			offset = i[0] ? i.offset() : false;
	// 		if(offset){
	// 			w.stop();
	// 			w.animate({
	// 				scrollTop:  offset.top + (adjust || 0)
	// 			}, 400); 
	// 		}
	// 	},10);
	// }

	// function next(current, state, container, labels, transition){
	// 	var label;
	// 	if(!labels.length){
	// 		return;
	// 	}
	// 	switch(state){
	// 		case 'in':
	// 			if(current === undefined || current.length === 0){
	// 				current = labels;
	// 			}
	// 			label = current.first().clone();
	// 			container.find('inner-content').html(label).on('click',function(){
	// 				scrollToFirst(current.first().closest('comp-container'));
	// 			});
	// 			container.addClass('in');
	// 			setTimeout(function(){
	// 				next(current.slice(1),'out',container,labels,transition);
	// 			},transition + (label.text().split(' ').length * 250) + 2000);
	// 			break;
	// 		case 'out':
	// 			if(container.attr('over') === 'over'){
	// 				container.one('mouseout','h3',function(){
	// 					next(current,'out',container,labels,transition);
	// 				});
	// 			}
	// 			else {
	// 				container.addClass('out');
	// 				setTimeout(function(){
	// 					next(current,'reset',container,labels,transition);
	// 				},transition);
	// 			}
	// 			break;
	// 		case 'reset': 
	// 			container.find('inner-content').html('');
	// 			container.removeClass('out in');
	// 			setTimeout(function(){
	// 				next(current,'in',container,labels,transition);
	// 			},transition + 500);
	// 	}
	// }

	// $('comp-container[intro]').each(function(){
	// 	var n = $(this);
	// 	n.on('mouseover','h3',function(){
	// 		n.attr('over','over');
	// 	})
	// 	n.on('mouseout','h3',function(){
	// 		n.attr('over','');
	// 	});
	// 	next(undefined, 'in', n,$('h3'), 500);
	// });

	$('comp-container[napa-intro] video').each(function(){
		var video = this,
			overlay = video.nextElementSibling,
			button = document.createElement('button');
		button.className = 'icon-mute';
		button.addEventListener('click',()=>{
			if(video.muted){
				video.muted = false;
				button.className = 'icon-unmute';
			}
			else {
				video.muted = true;
				button.className = 'icon-mute';
			}
		});
		if(overlay){
			overlay.appendChild(button);
		}
	});
	
});