'use strict';
$(function(){

	function updateSize(e){
		var data = e.data,
			iframe = data.iframe,
			container = data.container,
			ratio = data.ratio,
			finalWidth,
			finalHeight,
			containerHeight = container.outerHeight(),
			containerWidth = container.outerWidth(),
			containerRatio = containerHeight / containerWidth;

		if (containerRatio > ratio) {
			finalHeight = containerHeight;
			finalWidth = (containerHeight / ratio);
		} 
		else {
			finalWidth = containerWidth;
			finalHeight = (containerWidth / ratio);
		}
		iframe.css({
			width: finalWidth, 
			height: finalHeight
		});

	}

	$('comp-background > iframe').each(function(){

		var data = {
			iframe: $(this),
			container: $(this).closest('comp-container'),
			ratio: 9 / 16
		};

		$(window).on('resize',data,updateSize);

		updateSize({data:data});
	});
});