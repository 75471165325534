const setupScroller = (/** @type Element */ element) => {
	if (!element) return;
	const scrollArea = element.querySelector("[data-scroll-area]");
	if (!scrollArea) return;

	const scrollControlsContainer = element.querySelector(
		"[data-scroll-controlls]"
	);
	if (!scrollControlsContainer) return;

	const scrollLeft = element.querySelector("[data-scroll-left]");
	const scrollRight = element.querySelector("[data-scroll-right]");
	if (!scrollLeft || !scrollRight) return;

	let maxScrollAmount = scrollArea.scrollWidth - scrollArea.clientWidth;
	let scrollAmount = scrollArea.clientWidth / 2;
	console.log("MAX SCROLL AMOUNT", maxScrollAmount);

	if (maxScrollAmount <= 0) scrollControlsContainer.classList.add("md:hidden");

	scrollLeft.addEventListener("click", () => {
		const amount = scrollArea.scrollLeft - scrollAmount;
		scrollArea.scrollTo({ left: amount <= 0 ? 0 : amount, behavior: "smooth" });
	});
	scrollRight.addEventListener("click", () => {
		const amount = scrollArea.scrollLeft + scrollAmount;
		scrollArea.scrollTo({
			left: amount >= maxScrollAmount ? maxScrollAmount : amount,
			behavior: "smooth",
		});
	});

	window.addEventListener("resize", () => {
		maxScrollAmount = scrollArea.scrollWidth - scrollArea.clientWidth;
		scrollAmount = scrollArea.clientWidth / 2;
		if (maxScrollAmount <= 0)
			scrollControlsContainer.classList.add("md:hidden");
		else scrollControlsContainer.classList.remove("md:hidden");
	});
};

Array.from(document.querySelectorAll("[data-scroller]")).forEach(setupScroller);
